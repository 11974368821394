import React from "react";
import PropTypes from "prop-types";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ZoraItem from "./ZoraItem";
import CreatorFilters from "./CreatorFilters";
function Card({ onClick, selected, title, itemId, item }) {
  const visibility = React.useContext(VisibilityContext);

  return (
    <div
      className="card"
      onClick={() => onClick(visibility)}
      tabIndex={0}
    >
      {
        /*
         <div>
        {title}

        visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}
        selected: {JSON.stringify(!!selected)}
              </div>

        */
      }
      <ZoraItem key={itemId} itemId={itemId} item={item} />


    </div>
  );
}

const CreatorWork = () => {
  const items = ["0x8d04a8c79cEB0889Bdd12acdF3Fa9D207eD3Ff63/1",
    "0xabEFBc9fD2F806065b4f3C237d4b59D9A97Bcac7/16323",
    "0x8d04a8c79cEB0889Bdd12acdF3Fa9D207eD3Ff63/1",
    "0x8d04a8c79cEB0889Bdd12acdF3Fa9D207eD3Ff63/1",
    "0xabEFBc9fD2F806065b4f3C237d4b59D9A97Bcac7/16323"];

  return (
    <div>
      <CreatorFilters />

      <div className="selection-container">
        <h3>Everything Connected Collection ✨</h3>
        <div className="creator-container">
          <ScrollMenu>
            {items.map((item, id) => (
              <Card
                key={"card_" + id}
                itemId={id} // NOTE: itemId is required for track items
                item={item}
                title={id}
                /*onClick={Rightrrow}*/
                selected={true}
              />
            ))}
          </ScrollMenu>
        </div>
      </div>
    </div>
  );
}

export default CreatorWork;
