import React from 'react'
import Carousel from 'better-react-carousel';
import styles from "assets/jss/admin-styles/views/channelsStyle.js";
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(styles);


export const ChannelsCarousel = (props) => {
    const classes = useStyles();
    const channels = props.channels;
    const channelIndex = props.activeChannelIndex;

    return (
        <Carousel className={classes.channelsCarousel} cols={11} rows={1} gap={10} hideArrow={false} autoplay={true} loop>
            {
                channels.map((channel, index) => {
                    const itemClassName = (index===channelIndex)? `${classes.channelsCarouselItem} ${classes.channelsCarouselItemHighlight}`: classes.channelsCarouselItem;
                    return (
                        <Carousel.Item key={"channelItem_"+index}>
                            <div onClick={()=> { props.handleActiveChannelChange(index) }} className={classes.channelsCarouselItemContainer}>
                                <img className={itemClassName} src={channel.coverURL} />
                                <center>{channel.name}</center>
                            </div>
                        </Carousel.Item>
                    )
                })
            }
        </Carousel>
    )
}
