import { useAuth } from 'AuthProvider';
import {
    Navigate, useLocation,
} from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
    const { token } = useAuth();
    const location = useLocation();
    
    if ((!localStorage.getItem("token") || localStorage.getItem("token")  === "null") && !token) {
        console.info("No token found");
        return <Navigate to="/login" replace state={{ from: location }} />
    }

    return children;
}