import {
  Player,
  LivepeerConfig,
  createReactClient,
  studioProvider,
} from '@livepeer/react';
import { Image } from 'antd';

import * as React from 'react';

const playbackId = '35e2zh8jis0y52r6';

const livepeerTheme = {
  colors: {
    accent: 'rgb(0, 145, 255)',
    containerBorderColor: 'rgba(0, 145, 255, 0.9)',
  },
  fonts: {
    display: 'Inter',
  },
};

const PosterImage = (props) => {
  return (
    <img
      src={props.src}
      style={{
        width: "100%"
      }}
    />
  );
};

export function Livepeer(props) {
  const client = createReactClient({
    provider: studioProvider({ apiKey: 'a4518d22-9766-467b-a5f7-957b164d6ad2' }),
  });

  return (
    <LivepeerConfig client={client} theme={livepeerTheme}>
      <Player
        //title="R3 TV"      
        //playbackId={playbackId}
        {...props}
        src={props.src}
        poster={props.thumbnail ? <PosterImage src={props.thumbnail} /> : null}
        showTitle={false}
        aspectRatio='16to9'
        controls={{
          autohide: 3000,
        }}
        theme={{
          borderStyles: { containerBorderStyle: 'hidden' },
          radii: { containerBorderRadius: '10px' },
        }}
      />
    </LivepeerConfig>

  );
}