import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styles from "assets/jss/admin-styles/views/channelsStyle.js";
import * as Yup from 'yup';
import { makeStyles } from "@material-ui/core";
import { getDownloadURL, ref } from "firebase/storage";
import { app, storage } from "Firebase";
import { uploadFile } from "utils/file_uploader";
import { addDoc, collection, getFirestore, serverTimestamp } from "firebase/firestore";
import { LinearProgress } from "@material-ui/core";
import { useAuth } from "AuthProvider";
import { UploadButton } from "./UploadButton";
import { shorten_string } from "utils/string_help";

const useStyles = makeStyles(styles);
const schema = Yup.object().shape({
    title: Yup.string()
        .max(30, 'Must be 30 characters or less')
        .required('Required'),
    description: Yup.string()
        .max(120, 'Must be 120 characters or less')
        .required('Describe your video'),
})

export const UploadSection = (props) => {
    const classes = useStyles();
    const channel = props.channel;
    const db = getFirestore(app);

    const user = useAuth();

    const [uploadProgress, setProgress] = useState(-1);

    let videoFile = {
        voteCount: 0,
    };
    const [localThumbnailFile, setLocalThumbnailFile] = useState(null);
    const [localVideoFile, setLocalVideoFile] = useState(null);

    const thumbnailUploadComplete = (uploadTask) => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            videoFile = { ...videoFile, thumbnail: downloadURL };
        });
    }

    const videoUploadComplete = (uploadTask) => {
        setProgress(-1);
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

            const file = {
                ...videoFile,
                uploadedTs: serverTimestamp(),
                url: downloadURL,
            }
            const videosRef = collection(db, "channels", channel?.id, "videos");

            //TODO: implement error handling
            addDoc(videosRef, file).then(res => {
                props.handleUploadSuccess();
            });
        });
    }

    const validateVideoFile = () => {
        if (!localVideoFile || !localVideoFile.name) return "Please select your video file";
        if (!localVideoFile.type.match("video/*")) return "Please choose a valid video file (e.g. mp4, mov)";
        return null;
    }

    const validateThumbnailFile = () => {
        if (!localThumbnailFile) return null;
        if (!localThumbnailFile.type.match("image/*")) return "Wrong image file format";
        return null;
    }

    const uploadVideo = (values, { setSubmitting }) => {
        if (!user || !user.token) return;

        videoFile = {
            ...videoFile,
            creatorId: user.token.uid,
            creatorName: (user.token.displayName ? user.token.displayName : "Anonymous"),
            title: values.title,
            description: values.description,
        }


        if (localThumbnailFile) {
            const storageRef = ref(storage, 'images/' + localThumbnailFile.name);
            uploadFile(localThumbnailFile, storageRef, thumbnailUploadComplete);
        }

        if (localVideoFile) {
            const storageRef = ref(storage, 'videos/' + localVideoFile.name);
            uploadFile(localVideoFile, storageRef, videoUploadComplete, setProgress);
        }
    }

    return (
        <div className={classes.uploadSectionContainer}>
            <Formik
                initialValues={{
                    title: "",
                    description: "",
                    thumbnail: "",
                    videoFile: ""
                }}
                validationSchema={schema}
                onSubmit={uploadVideo}>

                <Form>
                    <div className={classes.videoUploadInfoContainer}>
                        <Field className={`${classes.videoUploadTitleField} ${classes.videoFormField}`}
                            name="title"
                            type="text"
                            placeholder="Write a title for your video" />
                        <ErrorMessage component="div" className={classes.errorText} name="title" />
                        <br />
                        <Field className={`${classes.videoUploadDescriptionField} ${classes.videoFormField}`}
                            name="description"
                            type="textarea"
                            placeholder="Describe the video in 120 characters" />
                        <ErrorMessage component="div" className={classes.errorText} name="description" />
                    </div>
                    <div className={classes.videoUploadThumbnailContainer}>
                        <Field
                            name="thumbnail"
                            validate={validateThumbnailFile}
                        >
                            {({ field }) => (
                                <>
                                    <center>
                                        <span {...field}>
                                            {localThumbnailFile ? shorten_string(localThumbnailFile.name, 12) : "Custom Thumbnail"}
                                        </span>

                                        <ErrorMessage component="div" className={classes.errorText} name="thumbnail" />
                                        <br />
                                        {
                                            uploadProgress === -1 ?

                                                <UploadButton
                                                    className={classes.secondaryButton}
                                                    handleUploadClick={(e) => {
                                                        setLocalThumbnailFile(e.target?.files[0]);
                                                    }}
                                                /> :
                                                <button className={classes.secondaryButton} disabled />
                                        }

                                    </center>
                                </>
                            )}
                        </Field>
                    </div>
                    <div className={classes.videoUploadContainer}>
                        <Field
                            name="videoFile"
                            validate={validateVideoFile}
                        >
                            {({ field }) => (
                                <>
                                    <span {...field} className={classes.videoFormField}>
                                        {localVideoFile ? shorten_string(localVideoFile.name, 30) : "Upload your video here "}
                                    </span>
                                    {
                                        uploadProgress === -1 ?
                                            <UploadButton
                                                className={classes.secondaryButton}
                                                handleUploadClick={(e) => {
                                                    setLocalVideoFile(e.target?.files[0])
                                                }}
                                            /> : null
                                    }


                                    <ErrorMessage component="div" className={classes.errorText} name="videoFile" />
                                </>
                            )}
                        </Field>

                    </div>

                    <div className={classes.videoUploadContainerButtons}>
                        {
                            uploadProgress === -1 ?
                                <>
                                    <button type="button"
                                        onClick={props.handleUploadCancel}
                                        className={`${classes.thirdButton} ${classes.videoUploadContainerButton}`}>
                                        Cancel
                                    </button>
                                    <button type="submit"
                                        className={`${classes.primaryButton} ${classes.videoUploadContainerButton}`}>
                                        + Post Video
                                    </button>
                                </> :
                                <button type="button" disabled
                                    className={`${classes.primaryButton} ${classes.videoUploadContainerButton}`}>
                                    Uploading...
                                </button>

                        }


                    </div>
                </Form>
            </Formik>
            {
                uploadProgress > 0 ? <LinearProgress variant="determinate" value={uploadProgress} /> : null
            }
        </div >
    )
}