import React, { useEffect, useState } from "react";
import styles from "assets/css/auth-styles";
import r3windLogo from "assets/img/r3windlogo.png";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";

const AuthPage = (props) => {
    const LOGIN_WITH_EMAIL = 0;
    const LOGIN_WITH_GOOGLE = 1;

    //TODO: use a hook to check if user exists.
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const { onLogin, onLoginWithGoogle, onRegister } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);

    const goToApplication = () => {
        setErrorMessage(null);
        const origin = location.state?.from?.pathname || '/channels';
        navigate(origin);
    }

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleRegister = async () => {
        const result = await onRegister(email, password);

        if (result.status === 1) {
            //redirect to website
            console.log(result.message);
            goToApplication();
        } else {
            console.error(result.message);
            setErrorMessage(result.message);
        }
    }

    const handleLogin = async (method) => {
        let result = {}
        switch (method) {
            case LOGIN_WITH_EMAIL:
                result = await onLogin(email, password);
                break;
            case LOGIN_WITH_GOOGLE:
                result = await onLoginWithGoogle();
                break;
        }

        if (result.status === 1) {
            goToApplication();
        } else {
            console.error(result.message);
            setErrorMessage(result.message);
        }
    }

    return (
        <div className={classes.authPageContainer}>
            <div className={classes.authpageBanner}>
                <img className={classes.bannerLogo} src={r3windLogo} />
            </div>
            <div className={classes.formContainer}>
                <div className={classes.formHeader}>Connect</div>
                <input
                    type="email"
                    className={classes.formField}
                    placeholder="Email"
                    onChange={handleEmail}
                />
                <input
                    type="password"
                    className={classes.formField}
                    placeholder="Password"
                    onChange={handlePassword}
                />
                <div className={classes.formButtons}>
                    <button className={classes.joinButton} onClick={() => handleRegister()}>
                        Join
                    </button>
                    <button className={classes.loginButton} onClick={() => handleLogin(LOGIN_WITH_EMAIL)}>
                        Login
                    </button>
                </div>
                {errorMessage && (<label className={classes.errorMessage}>{errorMessage}</label>)}

                <hr className={classes.separator} />
                <button className={classes.connectWithButton} onClick={() => handleLogin(LOGIN_WITH_GOOGLE)}>
                    Connect with Google
                </button>
            </div>

        </div>
    )
}

export default AuthPage;
