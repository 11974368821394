export const sort = (original, sortType = "voteCount", order = "desc") => {
    const list = [].concat(original);

    list.sort((a, b) => {
        if (a[sortType] < b[sortType]) return 1;
        if (a[sortType] > b[sortType]) return -1;
        return 0;
    });

    if (order === "asc")
        return list.reverse();
    else
        return list;
}
