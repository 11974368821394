import { blackColor } from "assets/jss/admin-styles";

const primaryColor="#3C2CFF";

const authStyles = {
    authpageBanner: {
        backgroundColor: "#3C2CFF",
        width: "100%",
        height: "167px",
        position: "relative"
    },
    bannerLogo: {
        position: "relative",
        width:"250px",
        margin: "0px auto",
        display: "flex",
        top: "35%",
      
    },
    authPageContainer: {
        backgroundColor: "#1A1C21",
        color: "white",
        width: "100%",
        height: "100vh",
    },
    formContainer: {
        alignItems: "center",
        width: "60%",
        textAlign: "left",
        margin: "0px auto",

    },
    formHeader: {
        fontSize: "25px",
        padding: "25px 20px 20px 10px",
        textAlign: "left",
        fontWeight: "bold"
    },
    formButtons: {
        display: "flex",
        justifyContent: "space-between",
        margin: "30px 0px"
    },
    joinButton: {
        display: "inline-block",
        width: "20%",
        padding: "10px 15px",
        border: "solid 2px "+ primaryColor,
        backgroundColor: "black",
        borderRadius: "10px"
    },
    loginButton: {
        display: "inline-block",
        width: "20%",
        padding: "10px 15px",
        border: "solid 0px",
        backgroundColor: primaryColor,
        borderRadius: "10px"
    },
    errorMessage: {
        color: "red",
        fontSize: "small",
        margin: "0 0 10px 0"
    },
    separator: {
        border: "1px solid #25282F",
    },
    connectWithButton: {
        width: "100%",
        padding: "10px 15px",
        backgroundColor: primaryColor,
        margin: "30px 0px",
        borderRadius: "5px"
    },
    formField: {
        fontWeight: "bold",
        backgroundColor: blackColor,
        width: "98%",
        border: "none",
        height: "40px",
        padding: "5px 15px",
        borderRadius: "30px",
        margin: "10px 0",
        color: "#868686"
    }
};

export default authStyles;