import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBX7gPrBgB7jdk-PZeZK8MWdqGWQ4Q1CWo",
    authDomain: "r3wind.firebaseapp.com",
    databaseURL: "https://r3wind-default-rtdb.firebaseio.com",
    projectId: "r3wind",
    storageBucket: "r3wind.appspot.com",
    messagingSenderId: "166621851246",
    appId: "1:166621851246:web:d219a3cbd8a79772bc807f",
    measurementId: "G-YE5LTME41L"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LfjWx4kAAAAAIzKQG1pCChXCwBQNjpbigGVJ4ja'),
  
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });
  

export const storage = getStorage();
export const analytics = getAnalytics(app);
export const auth = getAuth(app);

const firebaseFunctions = getFunctions(app);
const db = getDatabase(app);

if (process.env.NODE_ENV == "development") {
    connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
    connectStorageEmulator(storage, "localhost", "9199") ;
    connectFirestoreEmulator(getFirestore(app), 'localhost', 8001);
    //connectAuthEmulator(auth, "http://localhost:9099");
    connectDatabaseEmulator(db, "localhost", 9000);
    
}

export const db_addVideo = httpsCallable(firebaseFunctions, 'addVideo');
export const db_addPage = httpsCallable(firebaseFunctions, 'addPage');
export const db_addLink = httpsCallable(firebaseFunctions, 'addLink');
export const db_getLink = httpsCallable(firebaseFunctions, 'getLink');
export const db_isValidLink = httpsCallable(firebaseFunctions, 'isValidLink');
