const warn = () => console.log("haven't resgiter the InteractiveSidePane yet");

let interactiveSidePane = {
  open: warn,
  close: warn,
};

/**
 * this method is to save the referece of the singleton component
 */
export const registerInteractiveSidePane = ref => {
  if (ref) {
    interactiveSidePane.open = ref.open;
    interactiveSidePane.close = ref.close;
  } else {
    interactiveSidePane.open = warn;
    interactiveSidePane.close = warn;
  }
};

export { default as InteractiveSidePaneInstance } from "./InteractiveSidePane";

/**
 * export the open and close method
 */
export default interactiveSidePane;
