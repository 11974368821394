import WalletConnect from '@walletconnect/web3-provider';
import { ethers } from 'ethers';
import { SignatureType, SiweMessage } from 'siwe';


const METAMASK = 'metamask';
const WALLET_CONNECT = 'walletconnect';
//const BASE_URL = 'https://us-central1-daoregistry-6bddb.cloudfunctions.net';
//const BASE_URL = 'http://localhost:3010';

const metamask = window.ethereum;
let walletConnect = null;

export const signIn = async (connector, res) => {
    console.info("Signing in");
    let provider = null;
    if (connector === METAMASK) {
        await metamask.request({
            method: 'eth_requestAccounts'
        });
        provider = new ethers.providers.Web3Provider(metamask);
    } else {
        walletConnect = new WalletConnect({
            infuraId: '8fcacee838e04f31b6ec145eb98879c8'
        });
        walletConnect.enable();
        
        provider = new ethers.providers.Web3Provider(walletConnect);
    }

    const [address] = await provider.listAccounts();
    if (!address) {
        throw new Error('Address not found.');
    }
  
    let ens = "";
    try {
        ens = await provider.lookupAddress(address);
    } catch (error) {
        console.error(error);
    }
/*
    const nonce = await fetch(`${BASE_URL}/api/nonce`)
        .then(await function (res) {
            return res.text().then(data => JSON.parse(data).msg);
        }).catch((reason) => {
            console.error("Failed to generate nonce");
            console.error(reason);
        });
*/        
  
    const message = new SiweMessage({
        statement: 'You agree to share your wallet address with R3wind by signing this request. Your address will be used for transferring any returns generated via your unique link.',
        domain: document.location.host,
        address,
        chainId: `${await provider.getNetwork().then(({ chainId }) => chainId)}`,
        uri: document.location.origin,
        version: '1',
        type: SignatureType.PERSONAL_SIGNATURE,
 //       nonce: nonce,
    });


    const signature = await provider.getSigner().signMessage(message.signMessage());
    message.signature = signature;

    res(address);
}
