import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import styles from "assets/jss/admin-styles/views/channelsStyle.js";

import Videos from "./Videos";

const useStyles = makeStyles(styles);

export const Channel = (props) => {
  const classes = useStyles();
  const sortTypes = [
    { value: "voteCount", label: "Best" },
    { value: "uploadedTs", label: "Latest" }
  ];

  const channel = props.channel

  const [sortType, setSortType] = useState(sortTypes[0]);
  const DropdownComponent = (props) => {
    return (
      <Dropdown
        className={classes.dropdownListWrapper}
        controlClassName={classes.dropdownListControl}
        menuClassName={classes.dropdownMenu}
        value={sortType}
        options={sortTypes}
        onChange={props.handleVideoSort} />
    );
  }

  const handleVideoSort = (sortType) => {
    setSortType(sortType);
  }

  return (
    <>
      <div className={classes.channelsContainerHeader}>
        <label className={classes.channelNameHeader}>{channel?.name}</label>
        <DropdownComponent handleVideoSort={handleVideoSort} />
        <button onClick={() => props.handleUploadClick(channel)} className={`${classes.mainUploadButton} ${classes.primaryButton}`}>
          + Post a Video
        </button>
      </div>

      <Videos sortType={sortType} channel={channel} {...props} />
    </>
  );
};


