import React from "react";
import Popup from 'reactjs-popup';
import { RiShareBoxLine } from 'react-icons/ri';
import { MdContentCopy } from "react-icons/md";
import { signIn } from "utils/providers";
import { randomString, shorten_string } from "utils/string_help";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { db_addLink, db_getLink } from "Firebase";

class SharePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            walletAddress: null,
            uniqueLink: null,
            copyText: null,
        };
        this.addLink = this.addLink.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.copyLink = this.copyLink.bind(this);
        this.signIn = this.signIn.bind(this);
    }

    addLink = (link_id, owner_address) => {
        db_addLink({
            link_id: link_id,
            owner_address: owner_address,
        }).then((result) => {
            // Read result of the Cloud Function.
            const sanitizedMessage = result.data.text;
            console.info(result);
        }).catch((error) => {
            // Getting the Error details.
            const code = error.code;
            const message = error.message;
            const details = error.details;
        });
    }

    handleAddress = (address) => {
        if (address !== null && address !== undefined) {
            db_getLink({
                owner_address: address
            }).then(link => {
                let uniqueLink = "";
                if (link.data) {
                    uniqueLink = link.data;
                } else {
                    const link_id = randomString(8, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
                    uniqueLink = link_id;
                    this.addLink(link_id, address);
                } 

                this.setState({
                    walletAddress: address,
                    uniqueLink: ("app.r3wind.xyz/pages/").concat(uniqueLink)
                });
            })
        } else {
            console.error("Error linking wallet address");
        }
    }

    copyLink = (text, result) => {
        this.setState({
            copyText: result ? "Copied to clipboard!" : "Failed to copy to clipboard"
        });

        setTimeout(() => {
            this.setState({
                copyText: null
            })
        }, 1000);
    }

    signIn() {
        signIn("metamask", this.handleAddress);
    }

    render() {
        return (
            <Popup trigger={
                <button class="share-button">
                    Share
                </button>
            } modal nested >
                {
                    close => (
                        <div className="modal">
                            <button className="close" onClick={close}>
                                &times; </button>
                            <div className="header">
                                Share
                            </div>
                            <hr style={{ border: "solid 1px ", color: "#646464" }} />
                            <div className="content">
                                Share a unique link to this video and{' '}
                                <div style={{ display: "inline-block", borderBottom: "solid 1px #FF3F6F" }}>
                                    <b>earn 2% of any sales you generate.</b>
                                </div>

                            </div>
                            <div className="actions">
                                <button className="connect-button" onClick={this.signIn}>
                                    <RiShareBoxLine size={15} /> &nbsp;
                                    Connect
                                </button>
                                <div className="link-container">
                                    <table>
                                        <tr>
                                            <td>
                                                <div class="tooltip">
                                                    {this.state.copyText && (<span class="tooltiptext" >{this.state.copyText}</span>)}
                                                    <CopyToClipboard text={this.state.uniqueLink} onCopy={this.copyLink}>
                                                        <MdContentCopy
                                                            style={{ cursor: "pointer" }}
                                                            size={15} >
                                                        </MdContentCopy>
                                                    </CopyToClipboard>
                                                </div>
                                            </td>
                                            <td>
                                                {this.state.uniqueLink == null ? "Connect to generate a link" : this.state.uniqueLink}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <br /> <br />
                                {this.state.walletAddress && shorten_string(this.state.walletAddress)}

                            </div>
                        </div>
                    )
                }
            </Popup>
        );
    }

}

export default SharePopup;
