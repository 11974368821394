import zIndex from "@material-ui/core/styles/zIndex";
import {
  successColor,
  whiteColor,
  grayColor,
  dangerColor,
  r3windLightPurple,
} from "assets/jss/admin-styles.js";

import arrowUp from "assets/img/arrowUp.svg"
import arrowUpHighlight from "assets/img/arrowUpHighlight.svg"

import arrowDown from "assets/img/arrowDown.svg"
import arrowDownHighlight from "assets/img/arrowDownHighlight.svg"

const r3windBlue = "#3C2CFF";
const r3windBlueHover = "#4F40FF";

const channelsStyle = {

  //Boilerplate
  successText: {
    float: "left",
    color: successColor[0],
  },
  errorText: {
    color: dangerColor[0],
    fontSize: "12px",
  },
  smallPicture: {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
  },
  largePicture: {
    width: "155px",
    height: "155px",
    borderRadius: "5px",
  },
  primaryButton: {
    backgroundColor: "#0F1013",
    borderRadius: "5px",
    backgroundColor: r3windBlue,
    border: "2px solid " + r3windBlue,
    "&:hover": {
      backgroundColor: r3windBlueHover,
      border: "2px solid " + r3windBlueHover,
    },
  },
  secondaryButton: {
    backgroundColor: "#0F1013",
    borderRadius: "5px",
    border: "2px solid " + r3windBlue,
    "&:hover": {
      backgroundColor: "#191A1D",
    },
  },
  thirdButton: {
    backgroundColor: "transparent",
    borderRadius: "5px",
    border: "2px solid white",
    "&:hover": {
      backgroundColor: "grey",
    },
  },
  //Main Panel and header

  mainPanel: {
    color: whiteColor,
    width: "90%",
    margin: "0 auto",
    backgroundColor: "#0F1013",
    alignItems: "center",
    marginTop: "40px",
  },
  header: {
    display: "inline-block",
    marginBottom: "25px",
    width: "100%",
  },
  headerLogo: {
    width: "246px",
    height: "40px",
    float: "left",
  },
  headerRight: {
    float: "right",
  },


  //Carousel
  channelsCarousel: {
    height: "60px",
    width: "80vw",
  },
  channelsCarouselItemContainer: {
    fontSize: "12px",
    color: "#939393",
    "&:hover": {
      cursor: "pointer",
    },
  },
  channelsCarouselItem: {
    width: "100%",
    height: "60px",
    borderRadius: "5px",
  },
  channelsCarouselItemHighlight: {
    border: "3px solid " + r3windBlue,
    borderRadius: "5px",
  },


  channelContentWrapper: {
    width: "100%",
    margin: "25px 0px",
    display: "inline-block",
  },
  channelsContainer: {
    width: "60%",
    float: "left",
    minWidth: "600px",
    margin: "0 auto",
  },
  channelsContainerHeader: {
    lineHeight: "40px",
    marginBottom: "40px",
    width: "100%",

  },
  channelNameHeader: {
    float: "left",
    fontWeight: "500",
    fontSize: "24px",
  },
  dropdownListWrapper: {
    display: "inline-block",
    color: "white",
    borderRadius: "10px",
    padding: "5px",
    lineHeight: "0px",
    backgroundColor: "transparent",
    border: "1px solid white",
    verticalAlign: "middle",
    margin: "0px 10px",
  },
  dropdownMenu: {
    backgroundColor: "white",
    border: "0px",
    width: "80%",
    borderRadius: "0px 0px 5px 5px",
    color: "white",
  },
  dropdownItem: {
    backgroundColor: "white",
  },
  dropdownListControl: {
    backgroundColor: "transparent",
    border: "0px",
    color: "white",
    border: "0px",
    overflow: "initial",
    position: "initial",
    verticalAlign: "middle",

  },
  dropdownListArrow: {
    borderColor: "transparent transparent #FFF",
  },
  dropDownArrowDown: {
    borderColor: "white transparent transparent",
    borderWidth: "5px 5px 0px",
    borderStyle: "solid",
    content: ' ',
    display: "block",
    width: 0,
    height: 0,
    position: "absolute",
    right: "10px",

  },
  dropDownArrowUp: {
    borderColor: "transparent transparent white",
    borderWidth: "0px 5px 5px",
    borderStyle: "solid",
    content: ' ',
    display: "block",
    width: 0,
    height: 0,
    position: "absolute",
    right: "10px",
  },
  dropdownPlaceholder: {
    backgroundColor: "white",
  },
  mainUploadButton: {
    display: "inline-block",
    fontSize: "16px",
    float: "right",
    padding: "10px 20px",
  },
  channelInfo: {
    margin: "10px 0",
  },


  //Interactive section
  //Upload Section
  uploadSectionContainer: {
    margin: "40px 0",
    width: "100%",
  },
  videoUploadInfoContainer: {
    display: "inline-block",
  },
  videoUploadThumbnailContainer: {
    display: "inline-block",
  },
  videoFormField: {
    color: "#818181",
    outline: "none",
    width: "250px",
    borderStyle: "hidden",
    backgroundColor: "transparent",
  },
  videoUploadTitleField: {
    fontSize: "large",
    fontWeight: "700",
  },
  videoUploadDescriptionField: {
    fontSize: "small",
  },
  videoUploadThumbnailContainer: {
    float: "right",
    fontSize: "12px",
    lineHeight: "20px",
  },
  videoUploadContainer: {
    margin: "20px auto",
    padding: "100px 0px",
    width: "100%",
    border: "2px dashed #939393",
    borderRadius: "5px",
    textAlign: "center",
  },
  videoUploadContainerButtons: {
    float: "right",
  },
  videoUploadContainerButton: {
    margin: "0px 5px",
    padding: "5px 15px",
  },


  //Chat
  chatBoxWrapper: {
    margin: "20px 0",
    flexGrow: "1",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  chatHeader: {
    margin: "0 auto",
    display: "inline-block",
    minHeight: "40px",
    fontSize: "16px",
    lineHeight: "40px",
    borderBottom: `2px solid ${r3windBlue}`,
  },
  chatContainer: {
    width: "95%",
    height: "100%",
    overflow: "auto",
    minHeight: "200px",
    backgroundColor: "transparent",
    borderTop: "1px solid #25282F",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column-reverse",
  },
  chatMessage: {
    whiteSpace: 'nowrap',
    display: "inline-block",
    padding: "10px 0",
  },
  chatMessageContainer: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  chatUserPicture: {
    marginRight: "10px",
    float: "left",
    textAlign: "center",
    backgroundColor: "transparent",
    verticalAlign: "top",
  },
  chatMessageInfo: {
    whiteSpace: "normal",
  },
  chatMessageTitle: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    whiteSpace: "normal",
  },
  chatMessageBody: {
    whiteSpace: "normal",
    fontWeight: "400",
    fontSize: "12px",
  },
  chatFieldContainer: {
    padding: "10px 15px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#0F1013",
    borderRadius: "20px",
    marginTop: "10px",
  },
  chatField: {
    flexGrow: "2"
  },
  chatButtonContainer: {
    position: "relative",
    textAlign: "center",
    display: "inline-block",
    padding: "2px",
    borderRadius: "50%",
    overflow: "hidden",
    width: "20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  chatButton: {
    background: r3windBlue,
    borderRadius: "50%",
    verticalAlign: "middle",
    padding: "10%",
    position: "absolute",
    fontSize: "1.125em",
    fontWeight: "700",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    width: "100%",
    display: "inline-block",
    textDecoration: "none",
    textTransform: "uppercase",
    border: "none",
    "&:hover": {
      backgroundColor: r3windBlueHover
    }
  },
  chatButtonImage: {
    position: "sticky",

  },

  upArrowCardCategory: {
    width: "16px",
    height: "16px",
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  videosContainer: {
    width: "55%",
    float: "left",
  },
  videoContainer: {
    width: "100%",
    margin: "0px 0px 20px 30px",
    position: "relative",
  },
  arrowContainer: {
    display: "block",
    width: "20px",
    height: "14px",
    verticalAlign: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",

    "&:hover": {
      cursor: "pointer",
    },
  },
  arrowUp: {
    backgroundImage: `url(${arrowUp})`,
    "&:hover": {
      backgroundImage: `url(${arrowUpHighlight})`,
    },
  },
  arrowUpHighlighted: {
    backgroundImage: `url(${arrowUpHighlight})`,
  },
  arrowDown: {
    backgroundImage: `url(${arrowDown})`,

    "&:hover": {
      backgroundImage: `url(${arrowDownHighlight})`,
    },
  },
  arrowDownHighlighted: {
    backgroundImage: `url(${arrowDownHighlight})`,
  },
  channelEmptyMessage: {
    fontSize: "24px",
    marginTop: "100px",
    color: "yellow",
  },
  videoInfoContainer: {
    padding: "20px",
    position: "absolute",
    width: "-webkit-fill-available",
    display: "flex",
    borderRadius: "0px 0px 20px 20px",
    backgroundImage: "linear-gradient(180deg, rgba(27,27,27,1) 0%, rgba(15,15,15,0) 100%)",
    zIndex: "10",
    color: "white",
    lineHeight: "20px"
  },
  voteContainer: {
    float: "left",
    marginRight: "20px",
  },
  videoTextInfo: {
    float: "right",
  },
  videoTitle: {
    fontSize: "24px",
  },
  videoDescription: {
    fontSize: "12px",
    fontWeight: "400",
  },
  creatorInfoContainer: {
    position: "absolute",
    bottom: "60px",
    left: "20px",
    lineHeight: "40px",
    zIndex: "10",
  },
  creatorName: {
    display: "inline-block",
    verticalAlign: "top",
    marginLeft: "10px",
    zIndex: "10",
    color: "white",
    fontSize: "20px",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  sideChannelWrapper: {
    backgroundColor: "#1A1C21",
    borderRadius: "5px",
    width: "38%",
    float: "right",
    minHeight: "600px",
    height: "70vh",
  },
  sideChannelContainer: {
    margin: "0 auto",
    width: "95%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

  },
  sideChannelInfoWrapper: {
    marginTop: "20px",
  },
  sideChannelInfoLeftWrapper: {
    width: "35%",
    display: "inline-block",
  },
  sideCHannelInfoLeftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "180px",
  },
  channelCover: {
    width: "100%",
    border: "2px solid" + r3windBlue,
    height: "120px",
    borderRadius: "5px",
  },
  creatorCover: {
    width: "155px",
    border: "2px solid" + r3windBlue,
    height: "155px",
    borderRadius: "5px",
  },
  creatorSummary: {
    fontSize: "12px",
    verticalAlign: "top",
  },
  channelIcons: {
    display: "flex",
    justifyContent: "space-between",
  },
  creatorIcons: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "70%",

  },
  verticalLine: {
    border: "1px solid #868686",
    borderRadius: "5px",
  },
  sideChannelInfoRightWrapper: {
    display: "inline-block",
    position: "relative",
    width: "50%",
    marginLeft: "30px",
    verticalAlign: "top",
  },
  sideChannelInfoRightContainer: {
    minHeight: "180px",
    whiteSpace: "nowrap",
  },

  channelVotes: {
    width: "fit-content",
    verticalAlign: "top",
    display: "inline-block",
    backgroundColor: r3windBlue,
    borderRadius: "10px",
    padding: "2px 10px",
    fontSize: "10px",
  },

  sideChannelInfoTitle: {
    verticalAlign: "top",
    marginTop: "15px",
    fontSize: "24px",
    whiteSpace: "normal",
  },

  sideChannelInfoDescription: {
    fontSize: "12px",
    width: "100%",
    lineHeight: "13px",
    whiteSpace: "normal",
  },

  channelInfoButtonsContainer: {
    display: "flex",
    position: "absolute",
    bottom: "0",
  },

  sideChannelInfoButton: {
    padding: "5px 25px",
    borderRadius: "10px",
    backgroundColor: "black",
    fontSize: "12px",
    margin: "0px 10px",

  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
  },

  cardTitle: {
    color: r3windLightPurple,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

export default channelsStyle;
