import React from "react";

import { BsDiscord, BsInstagram, BsYoutube } from "react-icons/bs";
import { TbWorld } from 'react-icons/tb';
import { RiArrowUpSFill, RiTwitterFill } from "react-icons/ri";
import styles from "assets/jss/admin-styles/views/channelsStyle.js";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(styles);
export const ChannelsInfoSection = (props) => {
    const classes = useStyles();
    const channel = props.channel;

    const iconSize = 24;

    return (
        <>
            <div className={classes.sideChannelInfoLeftWrapper}>
                <div className={classes.sideCHannelInfoLeftContainer}>
                    <div className={classes.channelCoverContainer}>
                        <img className={classes.channelCover} src={channel?.coverURL} />
                    </div>
                    <div className={classes.channelIcons}>
                        <BsYoutube size={iconSize} />
                        <RiTwitterFill size={iconSize} />
                        <BsDiscord size={iconSize} />
                        <BsInstagram size={iconSize} />
                        <vl className={classes.verticalLine} />
                        <TbWorld size={iconSize} />
                    </div>
                </div>
            </div>

            <div className={classes.sideChannelInfoRightWrapper}>
                <div className={classes.sideChannelInfoRightContainer}>
                    <div className={classes.channelVotes}>
                        <RiArrowUpSFill /> {channel?.voteCount}
                    </div>
                    <br />
                    <div className={classes.sideChannelInfoTitle}>
                        {channel?.name}
                    </div>
                    <br />
                    <div className={classes.sideChannelInfoDescription}>
                        {channel?.description}
                    </div>
                    <br />
                    <div className={classes.channelInfoButtonsContainer}>
                        <button className={classes.sideChannelInfoButton}> Rules </button>
                        <button className={classes.sideChannelInfoButton}> Analytics </button>
                    </div>
                </div>
            </div>
        </>
    );
}