import React, { useEffect, useState } from "react";
import styles from "assets/jss/admin-styles/views/channelsStyle.js";
import { makeStyles } from "@material-ui/core";
import { useAuth } from "AuthProvider";
import { deleteDoc, doc, getFirestore, setDoc } from "firebase/firestore";
import { app } from "Firebase";

const useStyles = makeStyles(styles);

const DB_OPS = {
    REMOVE: 0,
    SET: 1,
};

export const VoteComponent = (props) => {
    const classes = useStyles();
    const user = useAuth();
    const db = getFirestore(app);
    const channel = props.channel
    const [userVote, setUserVote] = useState(props.userVote);
    const [op, setDBOp] = useState(null);
    const [voteCount, setVoteCount] = useState(props.video.voteCount);

    let arrowUpClassName = classes.arrowUp;
    let arrowDownClassName = classes.arrowDown;

    if (userVote) {
        if (userVote.liked) {
            arrowUpClassName = classes.arrowUpHighlighted;
        } else {
            arrowDownClassName = classes.arrowDownHighlighted;
        }
    }

    useEffect(() => {
        const updateDB = async (vote, op) => {

            if (!user?.token?.uid || !props.video?.id || op === null) return null;

            const votesCollectionRef = doc(db, "users", user.token.uid, "votesCollection", props.video.id);
            let response = null;
            switch (op) {
                case DB_OPS.REMOVE:
                    response = deleteDoc(votesCollectionRef);
                    break;
                case DB_OPS.SET:
                    response = setDoc(votesCollectionRef, {
                        liked: vote.liked,
                        channelId: channel?.id
                    });
                    break;
                default:
                //Do nothing
            }

            return response;
        }

        updateDB(userVote, op);
    }, [op, userVote, userVote?.liked, props.video.id]);


    const handleVote = async (liked) => {
        let newVoteCount = voteCount;
        let vote = null;
        let op = null;

        if (userVote) {
            if (userVote.liked === liked) {
                vote = null;
                op = DB_OPS.REMOVE;

                newVoteCount = liked ? newVoteCount - 1 : newVoteCount + 1;
                setVoteCount(newVoteCount);
                setUserVote(vote);

            } else {
                vote = { liked: liked }
                op = DB_OPS.SET;

                newVoteCount = liked ? newVoteCount + 2 : newVoteCount - 2;
            }
        } else {
            vote = { liked: liked }
            op = DB_OPS.SET;

            newVoteCount = liked ? newVoteCount + 1 : newVoteCount - 1;
        }

        setVoteCount(newVoteCount);
        setUserVote(vote);
        setDBOp(op);
    }
    //TODO: implement error handling


    return (
        <div className={classes.voteContainer}>
            <div className={`${classes.arrowContainer} ${arrowUpClassName}`} onClick={() => { handleVote(true) }} />
            <center><small>{voteCount}</small></center>
            <div className={`${classes.arrowContainer} ${arrowDownClassName}`} onClick={() => { handleVote(false) }} />
        </div>
    )

}