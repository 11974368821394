import React, { useRef } from "react"

export const UploadButton = (props) => {
    const inputFile = useRef(null);
    let type = props.type ? props.type : "button";
    const randomId = Math.floor(Math.random() * 101);

    const handleUploadClick = (e) => {
        inputFile.current.click();
    }

    return (
        <>
            <button onClick={handleUploadClick} className={props.className} type={type}>
                Upload
            </button>
            <input
                type='file'
                id={randomId}
                ref={inputFile}
                onChange={props.handleUploadClick}
                style={{ display: 'none' }} />
        </>

    )

}