import React from "react";
import { ChannelsInfoSection } from "views/Channels/ChannelsInfoSection";

export default class InfoSidePane extends React.Component {
    state = { component: <ChannelsInfoSection /> };


    open = component => {
        this.setState({ component: component });
    }

    render() {
        return this.state?.component
    }
}
