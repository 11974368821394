import React from "react";
import { GiSadCrab } from 'react-icons/gi'

const PageNotFound = () => {
    return (
        <center>
            <span>Page not found</span>
            <div className="page-not-found-div">
                <GiSadCrab size={100} /> <br />
                <span>Page not found</span>
            </div>
        </center>
    )
}

export default PageNotFound;