import React, { useEffect, useState } from "react";

import { BsDiscord, BsInstagram, BsYoutube } from "react-icons/bs";
import profilePicture from "assets/img/pp.jpeg";
import { TbWorld } from 'react-icons/tb';
import { RiTwitterFill } from "react-icons/ri";
import styles from "assets/jss/admin-styles/views/channelsStyle.js";
import { makeStyles } from "@material-ui/core";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { app } from "Firebase";

const useStyles = makeStyles(styles);
export const CreatorInfoSection = (props) => {
    const classes = useStyles();
    const db = getFirestore(app);
    const [creator, setCreator] = useState(props.creator);

    const iconSize = 24;

    useEffect(() => {
        if (!props.creator) return;

        setCreator(props.creator);
        const getCreatorInfo = async () => {
            const docRef = doc(db, "users", props.creator?.id);
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    setCreator({
                        ...props.creator,
                        summary: docSnap.data().summary
                    })
                } else {
                    console.error("Couldn't load creator information");
                }
            })
        }

        getCreatorInfo();
    }, [props.creator]);

    return (
        <div>
            <div>
                <div className={classes.sideChannelInfoLeftWrapper}>
                    <div className={classes.sideCHannelInfoLeftContainer}>
                        <div className={classes.channelCoverContainer}>
                            <img className={`${classes.profilePicture} ${classes.largePicture}`} src={profilePicture} />
                        </div>
                    </div>
                </div>

                <div className={classes.sideChannelInfoRightWrapper}>
                    <div className={classes.sideChannelInfoTitle}>
                        {creator?.displayName}
                    </div>
                    <p className={classes.creatorSummary}>
                        {creator?.summary}
                    </p>
                </div>
            </div>

            <div className={classes.creatorIcons} >
                <BsYoutube size={iconSize} />
                <RiTwitterFill size={iconSize} />
                <BsDiscord size={iconSize} />
                <BsInstagram size={iconSize} />
                <vl className={classes.verticalLine} />
                <TbWorld size={iconSize} />
            </div>
        </div>
    );
}