import React, { useState, createContext, useContext } from 'react';
import { app, auth } from "Firebase";
import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut
} from 'firebase/auth';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const db = getFirestore(app);

    onAuthStateChanged(auth, (currentUser) => {
        localStorage.setItem("token", JSON.stringify(currentUser));
        //Update user state. ALWAYS fetch from onAuthStateChanged
        setToken(currentUser);
    });

    const addToDB = async (user) => {
        if (!user) return;

        const usersRef = doc(db, "users", user.uid);
        let userData = {
            displayName: (user.displayName)? user.displayName : "Anonymous",
            summary: "",
        }
        //TODO: implement error handling
        await setDoc(usersRef, userData);
    }

    const register = async (email, pass) => {
        try {
            const token = await createUserWithEmailAndPassword(auth, email, pass);
            // Set Token 
            setToken(token);
            addToDB(token.user);

            return {
                status: 1,
                message: "success"
            }
        } catch (error) {
            console.log(error.code);
            console.log(error.message);
            let message = 'Failed to register, please try again.'
            if (error.code == 'auth/weak-password') {
                message = 'The password provided is too weak.';
            } else if (error.code == 'auth/email-already-in-use') {
                message = 'The account already exists for that email.';
            }

            //TODO: report error to firebase cloud.
            return {
                status: 0,
                message: message
            }
        }
    }

    const login = async (email, pass) => {
        try {
            const user = await signInWithEmailAndPassword(auth, email, pass);
            setToken(user);

            return {
                status: 1,
                message: "success"
            }
        } catch (error) {
            let message = 'Failed to login, please try again.';
            if (error.code == 'auth/user-not-found') {
                message = 'User not found.';
            } else if (error.code == 'auth/wrong-password') {
                message = 'Wrong password.';
            }

            //TODO: report error to firebase cloud.
            return {
                status: 0,
                message: message
            }
        }
    }

    const loginWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, new GoogleAuthProvider());

            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const user = result.user;
            const token = credential.accessToken;
            setToken(user);
            addToDB(user);

            return {
                status: 1,
                message: "success"
            }
        } catch (error) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData?.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            return {
                status: 0,
                message: errorMessage
            }
        }
    }
    const logout = async () => {
        await signOut(auth);
    }

    const value = {
        token,
        onRegister: register,
        onLogin: login,
        onLoginWithGoogle: loginWithGoogle,
        onLogout: logout
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
};


