import './App.css';

// Import the functions you need from the SDKs you need
import CreatorPage from 'components/viewer/CreatorPage';
import PageNotFound from './PageNotFound';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import AuthPage from 'components/auth/AuthPage';
import { AuthProvider } from 'AuthProvider';
import { ProtectedRoute } from 'ProtectedRoute';

import MainPage from 'views/Channels/MainPage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

console.info("App.js");

function App() {
  return (

    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<AuthPage />} />
          <Route path="/register" element={<AuthPage />} />
          <Route
            path="/users/:userId"
            element={<CreatorPage />} />
          <Route path="/" element={<ProtectedRoute><MainPage /></ProtectedRoute>} />
          <Route path="pagenotfound" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/pagenotfound" replace />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>

  )
}

export default App;
