import React from "react";
import { ChatBox } from "views/Channels/ChatBox";
import { UploadSection } from "views/Channels/UploadSection";

export default class InteractiveSidePane extends React.Component {
    state = { component: <></> };


    open = component => {
        this.setState({ component: component });
    }

    close = component => {
        this.setState({ component: <ChatBox /> })
    }
    render() {
        return this.state?.component
    }
}
