import React from "react";

import { Livepeer } from "components/viewer/Livepeer";
import styles from "assets/jss/admin-styles/views/channelsStyle.js";
import { makeStyles } from "@material-ui/core";
import { VoteComponent } from "./VoteComponent";
import { timeSince } from "utils/date_helper";
import profilePicture from "assets/img/pp.jpeg";

const useStyles = makeStyles(styles);

export const VideoComponent = (props) => {
  const classes = useStyles();
  const video = props.video;

  return (
    <div key={video.title} className={classes.videoContainer}>

      <div className={classes.videoInfoContainer}>
        <VoteComponent {...props} />
        <div className={classes.videoTextInfo}>
          <div className={classes.videoTitle}>
            {video.title} <small> - {timeSince(video.uploadedTs?.toDate())}</small>
          </div>
          <div className={classes.videoDescription}>
            {video.description}
          </div>
        </div>
      </div>
      <div className={classes.creatorInfoContainer}>
        <img className={`${classes.smallPicture}`} src={profilePicture} />

        <div className={classes.creatorName} onClick={() => {
          props.handleActiveCreatorChange({ id: video?.creatorId, displayName: video?.creatorName })
        }}>
          {video.creatorName}
        </div>

      </div>

      {
        props.active
          ? <Livepeer src={video?.url} thumbnail={video?.thumbnail} muted autoPlay />
          : <Livepeer src={video?.url} thumbnail={video?.thumbnail}/>
      }
    </div>

  )
}

