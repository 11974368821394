export const shorten_string = (str, length=8, start1=4, start2=str.length-5) => {
    if(length <= 0) return;
    if (str.length <= length) return str;
    return str.substring(0, start1) + "..." + str.substring(start2, str.length - 1);
}

export const randomString = (length, chars) => {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}
