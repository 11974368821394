import React, { useEffect } from "react";
import CreatorFilters from "./CreatorFilters";
import CreatorInfo from "./CreatorInfo";
import CreatorWork from "./CreatorWork";
import VideoEmbed from "./VideoEmbed";
import { useNavigate, useParams } from 'react-router-dom';

const CreatorPage = (props) => {
    let params = useParams();

    return (
        <div>

        </div>
    )
}

export default CreatorPage;
