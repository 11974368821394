const warn = () => console.log("haven't resgiter the SidePane yet");

let infoSidePane = {
  open: warn,
};

/**
 * this method is to save the referece of the singleton component
 */
export const registerInfoSidePane = ref => {
  if (ref) {
    infoSidePane.open = ref.open;
  } else {
    infoSidePane.open = warn;
  }
};

/**
 * export the popup component
 */
export { default as InfoSidePaneInstance } from "./InfoSidePane";

/**
 * export the open and close method
 */
export default infoSidePane;
