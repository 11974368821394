import { uploadBytesResumable } from "firebase/storage";

export const uploadFile = (file, storageRef, uploadComplete, uploadProgress = null) => {
    if (!file) {
        return;
    }
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
        (snapshot) => {
            if (uploadProgress) {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                uploadProgress(progress);
            }

            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    //console.log('Upload is running');
                    break;
            }
        },
        (error) => {
            if (uploadProgress) uploadProgress(-1);
            console.error("Failed file upload: " + error);
        },
        () => { uploadComplete(uploadTask) }
    )
}