import React from "react";
import PropTypes from "prop-types";
import SharePopup from "./SharePopup";
import { Livepeer } from "./Livepeer";

const VideoEmbed = ({ embedId }) => (

    <div className="video-responsive">
        <Livepeer src={"https://livesim.dashif.org/livesim/chunkdur_1/ato_7/testpic4_8s/Manifest.mpd"}/>
        <SharePopup />
    </div>
);

VideoEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default VideoEmbed;
