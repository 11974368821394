import React, { useEffect, useState } from "react";
import styles from "assets/jss/admin-styles/views/channelsStyle.js";
import { makeStyles } from "@material-ui/core";
import { VideoComponent } from "./VideoComponent";
import { collection, getDocs, getFirestore, orderBy, query } from "firebase/firestore";
import { sort } from "utils/sort_helper";
import { app } from "Firebase";
import { useAuth } from "AuthProvider";
import { CreatorInfoSection } from "./CreatorInfoSection";
import SidePane from "../InfoSidePane/InfoSidePane";

const useStyles = makeStyles(styles);

const Videos = (props) => {
  const classes = useStyles();
  const user = useAuth();
  const db = getFirestore(app);

  const [videos, setVideos] = useState(null);
  const [userVotes, setUserVotes] = useState([]);

  const channel = props.channel;
  const sortType = props.sortType;

  useEffect(() => {
    if (channel) {
      const getVideosQuery = query(collection(db, "channels", channel.id, "videos"), orderBy(sortType?.value, "desc"));

      getDocs(getVideosQuery).then(querySnapshot => {
        let updatedVideos = [];
        querySnapshot.forEach(doc => {
          updatedVideos.push({
            id: doc.id,
            ...doc.data()
          });
        });

        updatedVideos = sort(updatedVideos, sortType);

        setVideos(updatedVideos);
      });
    }
  }, [channel, sortType, props.newVideo]);

  useEffect(() => {
    if (user.token?.uid) {
      const getUserVotes = async () => {
        const votesRef = collection(db, "users", user?.token?.uid, "votesCollection");

        getDocs(votesRef).then((querySnapshot) => {
          let updatedVotes = {};
          // vote ID is the video ID
          querySnapshot.forEach(vote => {
            updatedVotes[vote.id] = vote.data();
          });
          setUserVotes(updatedVotes);
        });
      }

      getUserVotes();
    }
  }, [user, props.newVideo]);

  useEffect(() => {
    if (channel && videos?.length > 0) {
      let updatedVideos = sort(videos, sortType?.value);
      setVideos(updatedVideos);
    }
  }, [sortType]);

  const showCreatorInfo = (creator) => {
    SidePane.open(<CreatorInfoSection creator={creator} />)
  }

  let element = null;
  if (videos === null) {
    element = (
      <center>
        <div className={classes.channelEmptyMessage}>
          <h3>Loading...</h3>
        </div>
      </center>)
  } else if (videos.length === 0) {
    element = (
      <center>
        <div className={classes.channelEmptyMessage}>
          <h3>{channel?.name} does not have any videos, become the first contributor!</h3>
        </div>
      </center>
    )
  } else {
    element =
      videos.map((video, index) =>
        <VideoComponent
          key={video.id}
          video={video}
          active={index==0}
          userVote={userVotes[video.id]}
          showCreatorInfo={showCreatorInfo}
          {...props}
        />
      )
  }

  return (
    <div className={classes.videosContainer}>
      {element}
    </div>
  );
}

export default Videos;