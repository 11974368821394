import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import styles from "assets/jss/admin-styles/views/channelsStyle.js";
import profilePicture from "assets/img/pp.jpeg";
import enterImage from "assets/img/enter.svg";
import { useAuth } from "AuthProvider";
import { serverTimestamp, getDatabase, push, ref, onChildAdded, onValue } from "firebase/database";
import { timeSince } from "utils/date_helper";

const useStyles = makeStyles(styles);

const ChatMessage = (props) => {
    const classes = useStyles();
    const message = props.message;

    return (
        <div className={classes.chatMessage}>
            <div className={classes.chatUserPicture}>
                <img className={`${classes.smallPicture} `} src={profilePicture} alt="Send message" />
            </div>
            <div className={classes.chatMessageInfo}>
                <div className={classes.chatMessageTitle}>
                    {message?.senderName}
                </div>
                <div className={classes.chatMessageBody}>
                    {message?.message} <small><i>{timeSince(new Date(message.timestamp))}</i></small>
                </div>
            </div>
        </div>
    )
}

export const ChatBox = (props) => {
    const classes = useStyles();

    const user = useAuth();
    const db = getDatabase();
    const dbRef = ref(db, `channels/${props.channel?.id}/messages`);
    const [messages, setMessages] = useState([]);

    const unsubscribeRef = useRef();
    const textInputRef = useRef();
    const messagesEndRef = useRef();

    useEffect(() => {
        const getMessage = async () => {
            unsubscribeRef.current = onValue(dbRef, (data) => {
                let updatedMessages = [];

                data.forEach(message => {
                    updatedMessages.push(message.val());
                })
                setMessages(updatedMessages)
            });
        }

        getMessage();
        return () => unsubscribeRef?.current();
    }, [props.channel]);

    if (!props?.channel) return;
    if (!user.token.uid) return;

    const postMessage = async () => {
        const message = textInputRef?.current?.value;
        if (!message) {
            console.warn("Trying to send an empty message");
            return;
        }

        const chatMessage = {
            senderId: user.token.uid,
            senderName: user.token.displayName,
            timestamp: serverTimestamp(),
            message: message,
            //TODO: Add watching
        }

        return push(dbRef, chatMessage);
    }

    return (
        <div className={classes.chatBoxWrapper}>
            <div className={classes.chatHeader}>General Chat</div>
            <div className={classes.chatContainer}>
                <div className={classes.chatMessageContainer}>
                    {
                        messages?.map(msg =>
                            <ChatMessage key={"chat_" + Math.random() * 100} message={msg} />
                        )
                    }
                    <div ref={messagesEndRef} />
                </div >
            </div>
            <form className={classes.chatFieldContainer} onSubmit={(event) => { event.preventDefault() }}>
                <input type="text"
                    ref={textInputRef}
                    className={`${classes.videoFormField} ${classes.chatField}`}
                    placeholder="Message"
                />
                <div className={classes.chatButtonContainer} onClick={() => {
                    postMessage().then(() => {
                        textInputRef.current.value = "";
                    })
                }} >
                    <button type="submit" className={classes.chatButton} />
                    <img className={classes.chatButtonImage} src={enterImage} alt="Send message" />
                </div>
            </form>

        </div >

    )
}