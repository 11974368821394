import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import r3windLogo from "assets/img/r3windlogo.svg";
import profilePicture from "assets/img/pp.jpeg";
import 'react-dropdown/style.css';
import styles from "assets/jss/admin-styles/views/channelsStyle.js";
import { app } from "Firebase";
import {
    getFirestore,
    collection,
    getDocs,
    query,
    orderBy,
    limit,
} from "firebase/firestore";

import { ChannelsCarousel } from "./ChannelsCarousel";
import { Channel } from "./Channel";
import { CreatorInfoSection } from "./CreatorInfoSection";
import InfoSidePane, { registerInfoSidePane, InfoSidePaneInstance } from "../InfoSidePane";
import InteractiveSidePane, { InteractiveSidePaneInstance, registerInteractiveSidePane } from "../InteractiveSidePane";
import { ChannelsInfoSection } from "./ChannelsInfoSection";
import { UploadSection } from "./UploadSection";
import { ChatBox } from "./ChatBox";

const useStyles = makeStyles(styles);

const Header = () => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <img className={classes.headerLogo} src={r3windLogo} />
            <img className={`${classes.smallPicture} ${classes.headerRight}`} src={profilePicture} />
        </div>);
}


export default function MainPage(props) {
    const classes = useStyles();

    const [activeChannel, setActiveChannel] = useState(-1);
    const [channels, setChannels] = useState([]);
    const [newVideo, setNewVideo] = useState(false);

    const db = getFirestore(app);

    useEffect(() => {
        InteractiveSidePane.open(<ChatBox channel={channels[activeChannel]}/>);
    }, [channels, activeChannel]);

    useEffect(() => {
        const fetchChannels = async () => {
            const updatedChannels = [];
            //TODO: order by user preference 
            const getChannelsQuery = query(collection(db, "channels"), orderBy("voteCount", "desc"), limit(11));
            const querySnapshot = await getDocs(getChannelsQuery)

            querySnapshot.forEach((doc) => {
                const channel = {
                    id: doc.id,
                    ...doc.data()
                }
                updatedChannels.push(channel);
            })

            if (updatedChannels.length > 0) {
                setActiveChannel(0);
                InfoSidePane.open(<ChannelsInfoSection channel={updatedChannels[0]} />)
            }
            setChannels(updatedChannels);
        }
        fetchChannels();
    }, []);

    const handleActiveChannelChange = (index) => {
        if (index >= 0 && index < channels?.length) {
            setActiveChannel(index);
            InfoSidePane.open(<ChannelsInfoSection channel={channels[index]} />)
        }
    }

    const handleActiveCreatorChange = (creator) => {
        InfoSidePane.open(<CreatorInfoSection creator={creator} />)
    }

    const handleUploadEnd = () => {
        InteractiveSidePane.open(<ChatBox channel={channels[activeChannel]} />);
    }

    const handleUploadClick = (channel) => {
        setNewVideo(false);
        InteractiveSidePane.open(
            <UploadSection
                channel={channel}
                handleUploadSuccess={() => {
                    setNewVideo(true)
                    handleUploadEnd();
                }}
                handleUploadCancel={handleUploadEnd}
            />);
    }

    return (
        <div className={classes.mainPanel}>
            <Header />
            <ChannelsCarousel channels={channels} activeChannelIndex={activeChannel} handleActiveChannelChange={handleActiveChannelChange} />
            <div className={classes.channelContentWrapper}>
                <Channel
                    newVideo={newVideo}
                    channel={channels[activeChannel]}
                    handleActiveCreatorChange={handleActiveCreatorChange}
                    handleUploadClick={handleUploadClick} />

                <div className={classes.sideChannelWrapper}>
                    <div className={classes.sideChannelContainer}>
                        <div className={classes.sideChannelInfoWrapper}>
                            <InfoSidePaneInstance ref={registerInfoSidePane} />
                        </div>
                        <InteractiveSidePaneInstance ref={registerInteractiveSidePane} />
                    </div>
                </div>
                <div />
            </div>
        </div >
    );

}
